import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { useStaticQuery, graphql } from "gatsby";
import { Typography } from "@ryerson/frontend.typography";
import { useApplication } from "@ryerson/frontend.application";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";

type MarketReportStaticContent = {
	monthlyMarketReport: string;
	loadMoreReports: string;
	metalPerspective: string;
	featured: string;
	downloadEbook: string;
};

const staticContent: LanguageContent<MarketReportStaticContent> = {
	en: {
		monthlyMarketReport: "Monthly Market Report",
		loadMoreReports: "Load More Reports",
		metalPerspective: "Market Guides",
		featured: "Buying Metal Online: Every Question Answered",
		downloadEbook: "Buying Metal Online Every Question Answered",
	},
	fr: {
		monthlyMarketReport: "Rapport de marché mensuel",
		loadMoreReports: "Charger plus de rapports",
		metalPerspective: "Guides du marché",
		featured: "En vedette",
		downloadEbook: "Acheter du métal en ligne : réponse à chaque question",
	},
	es: {
		monthlyMarketReport: "Reporte mensual del mercado",
		loadMoreReports: "Cargar más reportes",
		metalPerspective: "Guías del mercado",
		featured: "Presentación",
		downloadEbook: "Comprar metal en línea Todas las preguntas respondidas",
	},
};

const Divider = styled.hr`
	display: block;
	width: 100%;
	opacity: 0.1;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const FirstReport = styled.div`
	display: block;
	width: 100%;
	height: 432px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
`;

const FirstReportText = styled.div`
	display: block;
	width: calc(100% - 114px);
	left: 32px;
	bottom: 38px;
	position: absolute;
	cursor: pointer;
	height: auto;
`;

const FirstReportIcon = styled.div`
	display: block;
	width: 24px;
	height: 24px;
	left: 0;
	position: absolute;
	top: 5px;
`;

const FirstReportBlueIcon = styled.div`
	display: block;
	position: absolute;
	bottom: 38px;
	right: 32px;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	line-height: 42px;
	text-align: center;
	cursor: pointer;
`;

const Left = styled.div`
	display: inline-block;
	width: 360px;
	height: 432px;
	margin-bottom: 40px;
	vertical-align: top;
`;

const Right = styled.div`
	display: inline-block;
	width: calc(100% - 360px);
	vertical-align: top;
	margin-bottom: 40px;
`;

const Report = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(16.667% - 40px);
	height: 196px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	margin-bottom: 40px;
`;

const ReportOverlay = styled.div`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	opacity: 1;
	width: 100%;
	transition: opacity 0.25s ease-in;
	&:hover {
		opacity: 0;
		cursor: pointer;
	}
`;

const ReportText = styled.div`
	display: block;
	width: calc(100% - 34px);
	left: 17px;
	bottom: 23px;
	position: absolute;
	height: auto;
`;

const ReportIcon = styled.div`
	display: block;
	width: 20px;
	height: 20px;
	left: 0;
	position: absolute;
	top: 0;
`;

const LoadMore = styled.div`
	display: block;
	margin: 0 auto;
	cursor: pointer;
	width: 210px;
	box-sizing: border-box;
	line-height: 36px;
	text-align: center;
	height: 40px;
	border-radius: 24px;
`;

const Book = styled.div`
	display: block;
	width: 100%;
	height: 676px;
`;

const LeftFloat = styled.div`
	position: absolute;
	left: 0;
	width: 48.61%;
	height: 580px;
`;
const RightFloat = styled.div`
	position: absolute;
	right: 0;
	width: 51.39%;
	height: 676px;
`;

const Spacer = styled.div`
	display: block;
	height: 20px;
	width: 100%;
`;

const Perspectives = styled.div`
	display: block;
	width: 100%;
	max-width: 560px;
	margin: 0 0 0 auto;
	padding-right: 100px;
	box-sizing: border-box;
`;

const Ebook = styled.div`
	display: block;
	width: 100%;
	max-width: 600px;
	padding-left: 100px;
	box-sizing: border-box;
	margin: 0 auto 0 0;
`;

const MarketReports: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const marketUpdates = useStaticQuery(graphql`
		query AllMarketUpdatesQuery {
			allContentfulMarketReport(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					contentful_id
					title
					reportType
					publishDate
					node_locale
					thumbnail {
						file {
							url
						}
					}
					downloadableFile {
						file {
							url
						}
					}
					subtitle
					description {
						description
					}
				}
			}
		}
	`);

	const downloadFile = (title: any, url: any) => {
		let a = document.createElement("a");
		a.href = url;
		a.download = title;
		a.target = "_blank";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
	let recentMarketUpdates: any = [];
	let recentMetalPerspectives: any = [];
	let recentEBooks: any = [];
	let previousId: any = null;
	let i = -1;
	let x = -1;
	let z = -1;
	marketUpdates.allContentfulMarketReport.nodes.forEach((node: any) => {
		if (node.reportType === "Market Report") {
			if (previousId !== node.contentful_id) {
				i++;
				recentMarketUpdates[i] = {};
			}
			recentMarketUpdates[i][node.node_locale.split("-").shift()] = node;
			recentMarketUpdates[i].date = node.publishDate;
			recentMarketUpdates[i].reportType = node.reportType;
		} else if (node.reportType === "Metal Perspective") {
			if (previousId !== node.contentful_id) {
				x++;
				recentMetalPerspectives[x] = {};
			}
			recentMetalPerspectives[x][node.node_locale.split("-").shift()] = node;
			recentMetalPerspectives[x].date = node.publishDate;
			recentMetalPerspectives[x].reportType = node.reportType;
		} else if (node.reportType === "eBook") {
			if (previousId !== node.contentful_id) {
				z++;
				recentEBooks[z] = {};
			}
			recentEBooks[z][node.node_locale.split("-").shift()] = node;
			recentEBooks[z].date = node.publishDate;
			recentEBooks[z].reportType = node.reportType;
		}
		previousId = node.contentful_id;
	});
	recentMarketUpdates.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});
	recentMetalPerspectives.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});
	recentEBooks.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});
	let defaultLoadMore = false;
	if (recentMarketUpdates.length <= 9) {
		defaultLoadMore = true;
	}
	const [loadMore, setLoadMore] = React.useState(defaultLoadMore);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" vPadding="100px">
					<Typography
						variant="h2"
						css={css`
							margin-bottom: 35px;
							letter-spacing: -2px;
						`}
					>
						{staticContent[language].monthlyMarketReport}
					</Typography>
					<Divider
						css={css`
							margin-bottom: 46px;
							margin-top: 0px;
							color: ${theme.colors.primary.darkGray};
						`}
					/>
					<Left>
						<FirstReport
							css={css`
								background-image: url(${recentMarketUpdates[0][language].thumbnail
									.file.url + "?w=360&h=432&fit=fill&fm=jpg"});
							`}
						>
							<FirstReportText
								onClick={() => {
									downloadFile(
										recentMarketUpdates[0][language].title + ".pdf",
										recentMarketUpdates[0][language].downloadableFile.file.url
									);
								}}
							>
								<FirstReportIcon>
									<Icon
										size="sm"
										icon="file-pdf"
										color={theme.colors.primary.white}
									/>
								</FirstReportIcon>
								<Typography
									variant="h4"
									color={theme.colors.primary.white}
									css={css`
										font-size: 20px;
										letter-spacing: -1px;
										display: block;
										width: 100%;
										height: auto;
										padding-left: 30px;
										padding-right: 30px;
										box-sizing: border-box;
									`}
								>
									{recentMarketUpdates[0][language].title}
								</Typography>
							</FirstReportText>
							<FirstReportBlueIcon
								onClick={() => {
									downloadFile(
										recentMarketUpdates[0][language].title + ".pdf",
										recentMarketUpdates[0][language].downloadableFile.file.url
									);
								}}
								css={css`
									background-color: ${theme.colors.primary.primaryBrand};
								`}
							>
								<Icon size="xs" icon="plus" color={theme.colors.primary.white} />
							</FirstReportBlueIcon>
						</FirstReport>
					</Left>

					<Right>
						{recentMarketUpdates.slice(1, 9).map((item: any, index: number) => {
							return (
								<Report
									key={index}
									css={css`
										background-image: url(${item[language].thumbnail.file.url +
										"?w=160&h=196&fit=fill&fm=jpg"});
										margin-left: 40px;
										width: calc(25% - 40px);
										margin-bottom: ${index > 3 ? "0px" : "40px"};
									`}
									onClick={() => {
										downloadFile(
											item[language].title + ".pdf",
											item[language].downloadableFile.file.url
										);
									}}
								>
									<ReportOverlay
										css={css`
											background-color: ${theme.colors.secondary.background};
										`}
									>
										<ReportText>
											<ReportIcon>
												<Icon
													icon="file-pdf"
													size="sm"
													color={theme.colors.primary.header}
												/>
											</ReportIcon>
											<Typography
												size="sm"
												color={theme.colors.primary.header}
												variant="div"
												weight="bold"
												css={css`
													display: block;
													width: 100%;
													padding-left: 25px;
													box-sizing: border-box;
												`}
											>
												{item[language].title}
											</Typography>
										</ReportText>
									</ReportOverlay>
								</Report>
							);
						})}
					</Right>
					{loadMore === false ? (
						<LoadMore
							css={css`
								border: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setLoadMore(true);
							}}
						>
							<Typography
								variant="span"
								color={theme.colors.primary.darkGray}
								weight="bold"
								size="sm"
							>
								{staticContent[language].loadMoreReports}
							</Typography>
						</LoadMore>
					) : (
						<>
							{recentMarketUpdates.slice(9, 15).map((item: any, index: number) => {
								return (
									<Report
										key={index}
										css={css`
											background-image: url(${item[language].thumbnail.file
												.url + "?w=160&h=196&fit=fill&fm=jpg"});
											margin-left: 0px;
											width: calc(16.666% - 33.333px);
											margin-right: ${(index + 1) % 6 === 0 ? "0px" : "40px"};
											margin-bottom: 40px;
										`}
										onClick={() => {
											downloadFile(
												item[language].title + ".pdf",
												item[language].downloadableFile.file.url
											);
										}}
									>
										<ReportOverlay
											css={css`
												background-color: ${theme.colors.secondary
													.background};
											`}
										>
											<ReportText>
												<ReportIcon>
													<Icon
														icon="file-pdf"
														size="sm"
														color={theme.colors.primary.header}
													/>
												</ReportIcon>
												<Typography
													size="sm"
													color={theme.colors.primary.header}
													variant="div"
													weight="bold"
													css={css`
														display: block;
														width: 100%;
														padding-left: 25px;
														box-sizing: border-box;
													`}
												>
													{item[language].title}
												</Typography>
											</ReportText>
										</ReportOverlay>
									</Report>
								);
							})}
						</>
					)}
					<Spacer
						css={css`
							height: 80px;
						`}
					/>
					<LeftFloat
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Perspectives>
							<Typography
								variant="h2"
								type="secondary"
								css={css`
									margin-bottom: 66px;
									letter-spacing: -2px;
									padding-top: 80px;
								`}
							>
								{staticContent[language].metalPerspective}
							</Typography>
							{recentMetalPerspectives.slice(0, 2).map((item: any, index: number) => {
								return (
									<Report
										key={index}
										css={css`
											background-image: url(${item[language].thumbnail.file
												.url + "?w=225&h=278&fit=fill&fm=jpg"});
											margin-left: 0px;
											width: calc(50% - 5px);
											margin-right: ${index === 0 ? "5px" : "0px"};
											margin-bottom: 0px;
											height: 278px;
										`}
										onClick={() => {
											downloadFile(
												item[language].title + ".pdf",
												item[language].downloadableFile.file.url
											);
										}}
									>
										<ReportOverlay
											css={css`
												background-color: ${theme.colors.tertiary
													.background};
											`}
										>
											<ReportText>
												<ReportIcon>
													<Icon
														icon="file-pdf"
														size="sm"
														color={theme.colors.tertiary.header}
													/>
												</ReportIcon>
												<Typography
													size="sm"
													color={theme.colors.tertiary.header}
													variant="div"
													weight="bold"
													css={css`
														display: block;
														width: 100%;
														padding-left: 25px;
														box-sizing: border-box;
													`}
												>
													{item[language].title}
												</Typography>
											</ReportText>
										</ReportOverlay>
									</Report>
								);
							})}
						</Perspectives>
					</LeftFloat>
					<RightFloat
						css={css`
							background-color: ${theme.colors.primary.darkerGray};
						`}
					>
						<Ebook>
							<Typography
								variant="h2"
								color={theme.colors.primary.white}
								css={css`
									margin-bottom: 32px;
									padding-top: 80px;
									letter-spacing: -2px;
								`}
							>
								{staticContent[language].featured}
							</Typography>
							<Divider
								css={css`
									color: ${theme.colors.primary.white};
									margin-bottom: 34px;
								`}
							/>
							<Typography
								variant="div"
								css={css`
									display: inline-block;
									width: calc(50% - 50px);
									height: auto;
									vertical-align: top;
									margin-right: 50px;
								`}
							>
								<Typography
									variant="h4"
									css={css`
										font-size: 20px;
										letter-spacing: -1px;
										margin-bottom: 30px;
									`}
									color={theme.colors.primary.white}
								>
									{recentEBooks[0][language].subtitle}
								</Typography>
								<Typography
									variant="div"
									size="md"
									css={css`
										white-space: pre-line;
									`}
									color={theme.colors.primary.white}
								>
									{recentEBooks[0][language].description.description}
								</Typography>
							</Typography>
							<Typography
								variant="div"
								css={css`
									display: inline-block;
									width: 50%;
									height: auto;
									vertical-align: top;
									img {
										width: 100%;
										height: auto;
									}
								`}
							>
								<img
									src={
										recentEBooks[0][language].thumbnail.file.url +
										"?w=250&h=312&fit=fill&fm=jpg"
									}
								/>
								<Spacer
									css={css`
										height: 35px;
									`}
								/>
								<Button
									paddingType="relaxed"
									type="secondary"
									shape="rounded"
									leftIcon="file-pdf"
									size="sm"
									onClick={() => {
										downloadFile(
											recentEBooks[0][language].title + ".pdf",
											recentEBooks[0][language].downloadableFile.file.url
										);
									}}
									label={staticContent[language].downloadEbook}
								/>
							</Typography>
						</Ebook>
					</RightFloat>
					<Book />
					<Spacer
						css={css`
							height: 40px;
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" hPadding="0px" vPadding="0px">
					<Typography
						variant="h2"
						css={css`
							margin-bottom: 20px;
							letter-spacing: -2px;
							padding-left: 20px;
							padding-right: 20px;
							box-sizing: border-box;
						`}
					>
						{staticContent[language].monthlyMarketReport}
					</Typography>
					<FirstReport
						css={css`
							background-image: url(${recentMarketUpdates[0][language].thumbnail.file
								.url + "?w=360&h=432&fit=fill&fm=jpg"});
							max-width: 360px;
							margin: 0 auto 50px auto;
						`}
					>
						<FirstReportText
							onClick={() => {
								downloadFile(
									recentMarketUpdates[0][language].title + ".pdf",
									recentMarketUpdates[0][language].downloadableFile.file.url
								);
							}}
						>
							<FirstReportIcon
								css={css`
									top: 1px;
								`}
							>
								<Icon
									size="sm"
									icon="file-pdf"
									color={theme.colors.primary.white}
								/>
							</FirstReportIcon>
							<Typography
								variant="h4"
								color={theme.colors.primary.white}
								css={css`
									font-size: 20px !important;
									letter-spacing: -1px;
									display: block;
									width: 100%;
									height: auto;
									padding-left: 30px;
									padding-right: 30px;
									box-sizing: border-box;
								`}
							>
								{recentMarketUpdates[0][language].title}
							</Typography>
						</FirstReportText>
						<FirstReportBlueIcon
							onClick={() => {
								downloadFile(
									recentMarketUpdates[0][language].title + ".pdf",
									recentMarketUpdates[0][language].downloadableFile.file.url
								);
							}}
							css={css`
								background-color: ${theme.colors.primary.primaryBrand};
							`}
						>
							<Icon size="xs" icon="plus" color={theme.colors.primary.white} />
						</FirstReportBlueIcon>
					</FirstReport>

					<Right
						css={css`
							display: block;
							width: 100%;
							padding-left: 20px;
							padding-right: 20px;
							box-sizing: border-box;
							max-width: 360px;
							margin-top: 0px;
							margin-left: auto;
							margin-right: auto;
						`}
					>
						{recentMarketUpdates.slice(1, 9).map((item: any, index: number) => {
							return (
								<Report
									key={index}
									css={css`
										background-image: url(${item[language].thumbnail.file.url +
										"?w=150&h=186&fit=fill&fm=jpg"});
										margin-left: 0;
										margin-right: ${index % 2 === 0 ? "20px" : "0px"};
										width: calc(50% - 10px);
										height: 230px;
										background-position: center top;
										background-size: 100% auto;
										margin-bottom: 20px;
									`}
									onClick={() => {
										downloadFile(
											item[language].title + ".pdf",
											item[language].downloadableFile.file.url
										);
									}}
								>
									<ReportOverlay
										css={css`
											background-color: transparent;
										`}
									>
										<ReportText
											css={css`
												bottom: 0px;
												left: 0px;
												width: 100%;
											`}
										>
											<ReportIcon>
												<Icon
													icon="file-pdf"
													size="md"
													color={theme.colors.primary.link}
												/>
											</ReportIcon>
											<Typography
												size="md"
												color={theme.colors.primary.link}
												variant="div"
												weight="bold"
												css={css`
													display: block;
													width: 100%;
													padding-left: 25px;
													box-sizing: border-box;
												`}
											>
												{item[language].title}
											</Typography>
										</ReportText>
									</ReportOverlay>
								</Report>
							);
						})}
					</Right>
					{loadMore === false ? (
						<>
							<LoadMore
								css={css`
									border: 1px solid ${theme.colors.primary.gray};
								`}
								onClick={() => {
									setLoadMore(true);
								}}
							>
								<Typography
									variant="span"
									color={theme.colors.primary.darkGray}
									weight="bold"
									size="sm"
								>
									{staticContent[language].loadMoreReports}
								</Typography>
							</LoadMore>
							<Spacer
								css={css`
									height: 40px;
								`}
							/>
						</>
					) : (
						<Right
							css={css`
								display: block;
								width: 100%;
								padding-left: 20px;
								padding-right: 20px;
								box-sizing: border-box;
								max-width: 360px;
								margin-top: -40px;
								margin-left: auto;
								margin-right: auto;
							`}
						>
							{recentMarketUpdates.slice(9, 15).map((item: any, index: number) => {
								return (
									<Report
										key={index}
										css={css`
											background-image: url(${item[language].thumbnail.file
												.url + "?w=150&h=186&fit=fill&fm=jpg"});
											margin-left: 0;
											margin-right: ${index % 2 === 0 ? "20px" : "0px"};
											width: calc(50% - 10px);
											height: 230px;
											background-position: center top;
											background-size: 100% auto;
											margin-bottom: 20px;
										`}
										onClick={() => {
											downloadFile(
												item[language].title + ".pdf",
												item[language].downloadableFile.file.url
											);
										}}
									>
										<ReportOverlay
											css={css`
												background-color: transparent;
											`}
										>
											<ReportText
												css={css`
													bottom: 0px;
													left: 0px;
													width: 100%;
												`}
											>
												<ReportIcon>
													<Icon
														icon="file-pdf"
														size="md"
														color={theme.colors.primary.link}
													/>
												</ReportIcon>
												<Typography
													size="md"
													color={theme.colors.primary.link}
													variant="div"
													weight="bold"
													css={css`
														display: block;
														width: 100%;
														padding-left: 25px;
														box-sizing: border-box;
													`}
												>
													{item[language].title}
												</Typography>
											</ReportText>
										</ReportOverlay>
									</Report>
								);
							})}
						</Right>
					)}
					<Spacer
						css={css`
							height: 20px;
						`}
					/>
					<LeftFloat
						css={css`
							background-color: ${theme.colors.secondary.background};
							display: block;
							position: relative;
							height: auto;
							width: 100%;
							padding: 30px 20px 40px 20px;
						`}
					>
						<Perspectives
							css={css`
								max-width: 360px;
								padding-right: 0px;
								margin: 0 auto 0 auto;
							`}
						>
							<Typography
								variant="h2"
								type="secondary"
								css={css`
									margin-bottom: 40px;
									letter-spacing: -2px;
									padding-top: 0px;
								`}
							>
								{staticContent[language].metalPerspective}
							</Typography>
							{recentMetalPerspectives.slice(0, 2).map((item: any, index: number) => {
								return (
									<Report
										key={index}
										css={css`
											background-image: url(${item[language].thumbnail.file
												.url + "?w=170&h=206&fit=fill&fm=jpg"});
											margin-left: 0;
											margin-right: ${index % 2 === 0 ? "20px" : "0px"};
											width: calc(50% - 10px);
											height: 250px;
											background-position: center top;
											background-size: 100% auto;
											margin-bottom: 20px;
										`}
										onClick={() => {
											downloadFile(
												item[language].title + ".pdf",
												item[language].downloadableFile.file.url
											);
										}}
									>
										<ReportOverlay
											css={css`
												background-color: transparent;
											`}
										>
											<ReportText
												css={css`
													bottom: 0px;
													left: 0px;
													width: 100%;
												`}
											>
												<ReportIcon>
													<Icon
														icon="file-pdf"
														size="md"
														color={theme.colors.primary.link}
													/>
												</ReportIcon>
												<Typography
													size="md"
													color={theme.colors.primary.link}
													variant="div"
													weight="bold"
													css={css`
														display: block;
														width: 100%;
														padding-left: 25px;
														box-sizing: border-box;
													`}
												>
													{item[language].title}
												</Typography>
											</ReportText>
										</ReportOverlay>
									</Report>
								);
							})}
						</Perspectives>
					</LeftFloat>
					<RightFloat
						css={css`
							background-color: ${theme.colors.primary.darkerGray};
							position: relative;
							width: 100%;
							height: auto;
							padding: 28px 20px 60px 20px;
							box-sizing: border-box;
						`}
					>
						<Ebook
							css={css`
								max-width: 360px;
								padding-left: 0px;
								margin: 0 auto 0 auto;
							`}
						>
							<Typography
								variant="h2"
								color={theme.colors.primary.white}
								css={css`
									margin-bottom: 26px;
									padding-top: 0px;
									letter-spacing: -2px;
								`}
							>
								{staticContent[language].featured}
							</Typography>
							<Typography
								variant="div"
								size="lg"
								weight="bold"
								css={css`
									margin-bottom: 25px;
								`}
								color={theme.colors.primary.white}
							>
								{recentEBooks[0][language].subtitle}
							</Typography>
							<Typography
								variant="div"
								css={css`
									display: inline-block;
									width: calc(50% - 10px);
									height: auto;
									vertical-align: top;
									margin-right: 20px;
								`}
							>
								<Typography
									variant="div"
									size="md"
									css={css`
										white-space: pre-line;
									`}
									color={theme.colors.primary.white}
								>
									{recentEBooks[0][language].description.description}
								</Typography>
							</Typography>
							<Typography
								variant="div"
								css={css`
									display: inline-block;
									width: calc(50% - 10px);
									height: auto;
									vertical-align: top;
									img {
										width: 100%;
										height: auto;
									}
								`}
							>
								<img
									src={
										recentEBooks[0][language].thumbnail.file.url +
										"?w=170&h=212&fit=fill&fm=jpg"
									}
								/>
							</Typography>
							<Spacer
								css={css`
									height: 35px;
								`}
							/>
							<Button
								fullwidth={true}
								type="secondary"
								shape="rounded"
								leftIcon="file-pdf"
								size="sm"
								onClick={() => {
									downloadFile(
										recentEBooks[0][language].title + ".pdf",
										recentEBooks[0][language].downloadableFile.file.url
									);
								}}
								label={staticContent[language].downloadEbook}
							/>
						</Ebook>
					</RightFloat>
				</ContentSection>
			</Media>
		</>
	);
};

export default MarketReports;
